/* eslint-disable */
import React, { useState } from 'react';
import { useAuth } from '~/hooks/contexts/useAuth';

import {
  currencyMask,
  creditCardMask,
  expirationDateMask,
  cpfMask,
  decimalMask,
} from '~/utils/masks';

import {
  cpfValidation,
  creditCardValidation,
  cvvValidation,
  expirationDateValidation,
  passwordValidation,
} from '~/utils/validatorsCard';

import Layout from '~/components/layout';

import useApi, { ApiMethod } from '~/hooks/useApi';
import endpoints from '~/services/endpoints';
import Loading from '~/components/loading';
import TitleApp from '~/components/title';
import Select from '~/components/select';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import Button from '~/components/button';
import { icons } from '~/assets';
import Dialog from '~/components/dialog';

import { PAYMENT_METHODS, CHECKOUT } from './constants';

import InsertTransactionPayload from '~/services/insertTransaction/payload';
import InsertTransactionResponse from '~/services/insertTransaction/response';

import { useHistory } from 'react-router-dom';
import Constants from '~/utils/constants';

interface IErrors {
  creditCard: boolean;
  cvv: boolean;
  value: boolean;
  password: boolean;
  cpf: boolean;
  expirationDate: boolean;
  checkout: boolean;
}

const TransactionHistory: React.FC = () => {
  const { user } = useAuth();

  const [transactionBy, setTransactionBy] = useState<'CPF' | 'CC'>('CC');

  const [popUpError, setPopUpError] = useState(false);
  const [popUpErrorMessage, setPopUpErrorMessage] = useState('');
  const [popUpIsOpen, setPopUpIsOpen] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [errors, setErrors] = useState({} as IErrors);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const [form, setForm] = useState({
    creditCard: '',
    cvv: '',
    value: currencyMask('0'),
    password: '',
    cpf: '',
    paymentMethod: '1',
    expirationDate: '',
    checkout: '',
  });

  const TokenCreditCard = useApi(
    endpoints.creditCard.tokenCreditCard,
    ApiMethod.POST,
  );

  const ClientByCard = useApi(
    endpoints.creditCard.clientByCard,
    ApiMethod.POST,
  );

  const PasswordValidation = useApi(
    endpoints.authentication.passwordValidation,
    ApiMethod.POST,
  );

  const InsertTransaction = useApi(
    endpoints.transaction.insert,
    ApiMethod.POST,
  );

  const handleValidation = () => {
    let isValid = true;
    setErrors({} as IErrors);

    if (transactionBy === 'CC') {
      if (!creditCardValidation(form.creditCard)) {
        setErrors((prev) => ({ ...prev, creditCard: true }));
        isValid = false;
      }

      if (!cvvValidation(form.cvv)) {
        setErrors((prev) => ({ ...prev, cvv: true }));
        isValid = false;
      }

      if (!expirationDateValidation(form.expirationDate)) {
        setErrors((prev) => ({ ...prev, expirationDate: true }));
        isValid = false;
      }
    } else if (!cpfValidation(form.cpf)) {
      setErrors((prev) => ({ ...prev, cpf: true }));
      isValid = false;
    }

    if (!form.value || Number(form.value.replace(',', '.')) <= 0) {
      setErrors((prev) => ({ ...prev, value: true }));
      isValid = false;
    }

    if (!passwordValidation(form.password)) {
      setErrors((prev) => ({ ...prev, password: true }));
      isValid = false;
    }

    if (!form.checkout) {
      setErrors((prev) => ({ ...prev, checkout: true }));
      isValid = false;
    }

    return isValid;
  };

  const history = useHistory();

  const handleSubmit = async () => {
    if (handleValidation()) {
      localStorage.setItem(Constants.XCODE_KEY, form.password);
      if (transactionBy === 'CC') {
        const payloadInsertTransaction: InsertTransactionPayload = {
          idTerminal: form.checkout,
          cartao: {
            cvv: form.cvv,
            dataVencimento: expirationDateMask(form.expirationDate).masked,
            numero: creditCardMask(form.creditCard).unmasked,
          },
          numeroParcelas: Number(form.paymentMethod),
          valor: decimalMask(form.value),
          debitoCredito: 0,
          descricao: 'Transação cartão de crédito',
          tipoTransacao: 0,
          idUsuario: user.id,
        };
        const insertTransaction = await InsertTransaction.callApi(
          payloadInsertTransaction,
        );

        const getTransaction =
          insertTransaction.data as InsertTransactionResponse;

        if (insertTransaction.status >= 400) {
          setPopUpError(true);
          setPopUpErrorMessage(getTransaction?.mensagem || 'Serviço indisponivel!');
          return;
        }

        history.push('/tax-receipt', {
          data: insertTransaction.data,
        });
        localStorage.removeItem(Constants.XCODE_KEY);

        setTransactionId(getTransaction?.data?.idAutorizador);
      }
    }
  };

  return (
    <>
      <Loading
        status={
          TokenCreditCard.loading ||
          PasswordValidation.loading ||
          InsertTransaction.loading
        }
      />

      <Dialog
        icon={icons.exclamation}
        open={popUpError}
        positiveLabel="Entendi"
        positiveAction={() => setPopUpError(false)}
        message={popUpErrorMessage}
      />
      <Dialog
        icon={icons.confirmation}
        open={dialogIsOpen}
        title="Confirmação"
        positiveLabel="Confirmar"
        positiveAction={() => handleSubmit() && setDialogIsOpen(false)}
        negativeLabel="Voltar"
        negativeAction={() => setDialogIsOpen(false)}
        message="Deseja confirmar a transação?"
      />

      <Layout>
        <ContainerFlex padding={16}>
          <ContainerFlex row padding={22} wrap>
            <ContainerFlex padding={10}>
              <TitleApp marginBottom={12}>Transações</TitleApp>

              <Select
                label="Escolha como será realizada a transação"
                value={transactionBy}
                options={[
                  { id: 'CC', value: 'Transação por Cartão de Crédito' },
                  // { id: 'CPF', value: 'Transação por CPF' },
                ]}
                onChange={(e) => setTransactionBy(e)}
              />

              <Input
                label="Valor"
                name="value"
                mask={MaskTypes.CURRENCY}
                placeholder="00,00"
                onChange={(e) => {
                  return setForm({
                    ...form,
                    value: currencyMask(e.target.value),
                  });
                }}
                value={form.value}
                maxLength={8}
                errorMessage={
                  errors.value ? 'Preencha esse campo corretamente' : undefined
                }
              />

              <Select
                label="Forma de pagamento"
                value={form.paymentMethod}
                options={PAYMENT_METHODS}
                onChange={(e) => setForm({ ...form, paymentMethod: e })}
              />

              {transactionBy === 'CC' && (
                <>
                  <Input
                    label="Número do cartão"
                    name="cc"
                    mask={MaskTypes.CREDIT_CARD}
                    placeholder="0000.0000.0000.0000"
                    onChange={(e) => {
                      return setForm({
                        ...form,
                        creditCard: e.target.value.unmasked,
                      });
                    }}
                    value={creditCardMask(form.creditCard).masked}
                    maxLength={19}
                    errorMessage={
                      errors.creditCard
                        ? 'Preencha esse campo corretamente'
                        : undefined
                    }
                  />

                  <Input
                    label="Data de expiração"
                    name="expiration"
                    mask={MaskTypes.NUMERIC}
                    placeholder="MM/AA"
                    onChange={(e) => {
                      return setForm({
                        ...form,
                        expirationDate: e.target.value,
                      });
                    }}
                    value={expirationDateMask(form.expirationDate).masked}
                    maxLength={5}
                    errorMessage={
                      errors.expirationDate
                        ? 'Preencha esse campo corretamente'
                        : undefined
                    }
                  />

                  <Input
                    label="CVV - Cod. Segurança (Vide verso do cartão)"
                    name="cvv"
                    mask={MaskTypes.NUMERIC}
                    placeholder="000"
                    onChange={(e) => {
                      return setForm({
                        ...form,
                        cvv: e.target.value,
                      });
                    }}
                    value={form.cvv}
                    maxLength={3}
                    errorMessage={
                      errors.cvv
                        ? 'Preencha esse campo corretamente'
                        : undefined
                    }
                  />

                  <Input
                    label="Senha do cartão"
                    name="password"
                    type="password"
                    mask={MaskTypes.NUMERIC}
                    placeholder="000000"
                    onChange={(e) => {
                      return setForm({
                        ...form,
                        password: e.target.value,
                      });
                    }}
                    value={form.password}
                    maxLength={6}
                    errorMessage={
                      errors.password
                        ? 'Preencha esse campo corretamente'
                        : undefined
                    }
                  />
                </>
              )}

              {transactionBy === 'CPF' && (
                <Input
                  label="CPF"
                  name="cpf"
                  mask={MaskTypes.CPF}
                  placeholder="000.000.000-00"
                  onChange={(e) => {
                    return setForm({
                      ...form,
                      cpf: cpfMask(e.target.value.unmasked).masked,
                    });
                  }}
                  value={form.cpf}
                  maxLength={14}
                />
              )}

              <Select
                label="Caixa"
                value={form.checkout}
                options={CHECKOUT}
                onChange={(e) => setForm({ ...form, checkout: e })}
                errorMessage={
                  errors.checkout
                    ? 'Preencha esse campo corretamente'
                    : undefined
                }
              />
            </ContainerFlex>

            <ContainerFlex end padding={30} flex={1.5}>
              <Button
                fitContent
                loading={
                  TokenCreditCard.loading ||
                  PasswordValidation.loading ||
                  InsertTransaction.loading
                }
                disabled={
                  TokenCreditCard.loading ||
                  PasswordValidation.loading ||
                  InsertTransaction.loading
                }
                onClick={() => {
                  if (handleValidation()) {
                    setDialogIsOpen(true);
                  }
                }}
              >
                <span>Efetuar transação</span>
              </Button>
            </ContainerFlex>
          </ContainerFlex>
        </ContainerFlex>
      </Layout>
    </>
  );
};

export default TransactionHistory;
